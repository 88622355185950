import React, { Component } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import CombatComboverBanner from '../content/CombatComboverBanner.png';
import ScoringSenateBanner from '../content/ScoringSenateBanner.png';
import ProSocksBanner from '../content/ProSocksBanner.png';
import EliteSportsBanner from '../content/EliteSportsBanner.png';
import BornToughBanner from '../content/BornToughBanner.png';
import PureBodyZenBanner from '../content/PureBodyZenBanner.png';
import CSCDPBJJBanner from '../content/CSCDPBJJBanner.png';

class Sponsors extends Component {
    render() {
        return (
            <React.Fragment>
                <Header  isMobile={this.props.isMobile}/>
                <center>
                    <div style={{width:'75%', textAlign:'center'}}>
                        <div className="ThemeFont" style={{color:'white',textAlign:'left',fontSize:'80px'}}>sponsors</div>
                        <hr className="AccentColor" style={{marginTop:'-25px'}} />
                    </div>

                    <a href='https://CombatCombover.com/' target='_blank'><img src={CombatComboverBanner} alt="" style={{width:'300px'}} /></a>
                    <br />
                    <br />
                    <div className={'BodyText'} style={{width:'300px',textAlign:'left'}}>
                        In an industry littered with companies trying to be cool and old school, we thought it’d be a good idea to be the brand that just doesn’t give a fuck.
                    </div>
                    <a href='https://Combatcombover.com/' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2">w e b s i t e</div>
                    </a>
                    <a href='mailto:support@CombatCombover.com' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2">c o n t a c t</div>
                    </a>
                    <a href='https://twitter.com/CombatCombover' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2"><i className='fa fa-twitter'></i></div>
                    </a>
                    <a href='https://www.facebook.com/combatcomboverofficial' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2"><i className='fa fa-facebook'></i></div>
                    </a>
                    <a href='https://www.instagram.com/combatcombover/' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2"><i className='fa fa-instagram'></i></div>
                    </a>

                    {/*
                    <br/><br/>
                    <br/><br/>
                    <a href='https://ScoringSenate.com/'  target='_blank'><img src={ScoringSenateBanner} alt="" style={{width:'300px'}}/></a>
                    <br />
                    <br />
                    <div className={'BodyText'} style={{width:'300px',textAlign:'left'}}>
                        Scoring Senate is a real-time, experts-based, & large-group scoring platform for combat sports. Don't let it go to the judges.
                    </div>
                    <a href='https://ScoringSenate.com/' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2">w e b s i t e</div>
                    </a>
                    <a href='mailto:info@ScoringSenate.com' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2">c o n t a c t</div>
                    </a>
                    <a href='https://twitter.com/ScoringSenate' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2"><i className='fa fa-twitter'></i></div>
                    </a>
                    <a href='https://www.facebook.com/ScoringSenate' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2"><i className='fa fa-facebook'></i></div>
                    </a>
                    <a href='https://www.instagram.com/ScoringSenate' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2"><i className='fa fa-instagram'></i></div>
                    </a>
                    */}


                    <br/><br/>
                    <br/><br/>
                    <a href='https://Prosocks.com/'  target='_blank'><img src={ProSocksBanner} alt="" style={{width:'300px'}}/></a>
                    <br />
                    <br />
                    <div className={'BodyText'} style={{width:'300px',textAlign:'left'}}>
                    At Prosocks.com we value all customers, whether you're ordering 1 or 10,000 pairs. We got you covered. There are no orders too big or too small for us, our production capacity is built to scale.
                    </div>
                    <a href='https://www.prosocks.com/' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2">w e b s i t e</div>
                    </a>
                    <a href='mailto:help@prosocks.com' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2">c o n t a c t</div>
                    </a>
                    {/* <a href='https://twitter.com/ScoringSenate' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2"><i className='fa fa-twitter'></i></div>
                    </a> */}
                    <a href='https://www.facebook.com/prosocksonline/' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2"><i className='fa fa-facebook'></i></div>
                    </a>
                    {/* <a href='https://www.instagram.com/ScoringSenate' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2"><i className='fa fa-instagram'></i></div>
                    </a> */}
   
                    <br/><br/>
                    <br/><br/>
                    <a href='https://www.elitesports.com/'  target='_blank'><img src={EliteSportsBanner} alt="" style={{width:'300px'}}/></a>
                    <br />
                    <br />
                    <div className={'BodyText'} style={{width:'300px',textAlign:'left'}}>
                    </div>
                    <a href='https://www.elitesports.com/' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2">w e b s i t e</div>
                    </a>
                    <a href='https://elitesports.zendesk.com/hc/en-us/requests/new?ticket_form_id=360000086292' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2">c o n t a c t</div>
                    </a>
                    <a href='https://twitter.com/ElitesportsMma' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2"><i className='fa fa-twitter'></i></div>
                    </a>
                    <a href='https://www.facebook.com/officialelitesports' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2"><i className='fa fa-facebook'></i></div>
                    </a>
                    <a href='https://www.instagram.com/elitesportscompany' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2"><i className='fa fa-instagram'></i></div>
                    </a>


                    <br/><br/>
                    <br/><br/>
                    <a href='https://www.borntough.com/'  target='_blank'><img src={BornToughBanner} alt="" style={{width:'300px'}}/></a>
                    <br />
                    <br />
                    <div className={'BodyText'} style={{width:'300px',textAlign:'left'}}>
                    The world is changing at an unprecedented rate, and so are our tastes and lifestyles. And as we have outgrown heavy cell phones with buttons and diesel-powered sedans, so have we outgrown traditional, oversized and unaesthetic sportswear.
                    </div>
                    <a href='https://www.borntough.com/' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2">w e b s i t e</div>
                    </a>
                    <a href='https://pbzcbd.com/contact-us/' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2">c o n t a c t</div>
                    </a>
                    <a href='https://twitter.com/borntoughUSA' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2"><i className='fa fa-twitter'></i></div>
                    </a>
                    <a href='https://www.facebook.com/borntoughofficial' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2"><i className='fa fa-facebook'></i></div>
                    </a>
                    <a href='https://www.instagram.com/borntoughofficial/' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2"><i className='fa fa-instagram'></i></div>
                    </a>

                    <br/><br/>
                    <br/><br/>
                    <a href='https://combatsportscenters.com/'  target='_blank'><img src={CSCDPBJJBanner} alt="" style={{width:'300px'}}/></a>
                    <br />
                    <br />
                    {/*
                    <div className={'BodyText'} style={{width:'300px',textAlign:'left'}}>
                    The world is changing at an unprecedented rate, and so are our tastes and lifestyles. And as we have outgrown heavy cell phones with buttons and diesel-powered sedans, so have we outgrown traditional, oversized and unaesthetic sportswear.
                    </div> */}
                    <a href='https://combatsportscenters.com/' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2">w e b s i t e</div>
                    </a>
                    {/*
                    <a href='https://pbzcbd.com/contact-us/' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2">c o n t a c t</div>
                    </a>
                    

                    <a href='https://twitter.com/borntoughUSA' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2"><i className='fa fa-twitter'></i></div>
                    </a>
                    */}
                    <a href='https://www.facebook.com/Combatsportscenter/' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2"><i className='fa fa-facebook'></i></div>
                    </a>
                    <a href='https://www.instagram.com/dpbjj/?hl=en' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2"><i className='fa fa-instagram'></i></div>
                    </a>
                    <a href='https://www.youtube.com/channel/UC-dv8f8AvSwunzsqNJixWnw' target='_blank'>
                        <div className="ThemeFont btn FontSizeLevel2"><i className='fa fa-youtube'></i></div>
                    </a>
                    



                    <br/><br/>
                    <br/><br/>
                    <br/><br/>
                    <br/><br/>
                    <br/><br/>
                    <br/><br/>
                    <br/><br/>
                    <br/><br/>
                    <br/><br/>
                </center>
                <Footer/>
            </React.Fragment>
        )
    }
}
export default Sponsors;