import React, { Component } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import interview from '../content/fertig002.jpg';

class About extends Component {
    componentDidMount () {
        let isMobile = this.props.isMobile;
    }
    
    render() {
        return (
            <React.Fragment>
                <Header isMobile={this.props.isMobile}/>
                <center>
                    <div style={{width:'75%', textAlign:'center'}}>
                        <div className="ThemeFont FontSizeLevel7" style={{color:'white',textAlign:'left'}}>about</div>
                        <hr className="AccentColor" style={{marginTop:'-20px'}} />
                    </div>

                    <div>
                        <div className="BodyText flexContainer" style={{color:'white', textAlign:'center'}}>
                                <div><img className="ImageHero" src={interview} alt="" style={{margin:'30px'}} /></div>
                                <div className="FontSizeLevel1 BodyText" style={{width:'300px',textAlign:'left',margin:'30px'}} >
                                    A combat sports fan for most of his life starting with pro wrestling, Garett was first drawn to pro wrestling as a child while sitting with his 
                                grandmother to watch Portland Wrestling with larger than life stars before they were stars, like Rowdy Roddy Piper, Jesse “The Body” Ventura, 
                                and Art Barr. As he got older, his love of pro wrestling never faded. He would often go to the matches when they would come to his hometown of Eugene, Oregon.
                                He remembers seeing the then WWF at MacArthur Court when he was 10-years old. While he was in line his father broke the news to him that pro wrestling was 
                                “fake.” This changed the way Garett saw the show from then on. Even though now he knew it was all a show, it didn’t deter his love for the art. 
                                <p/>
                                Garett was an athlete growing up, playing both football and baseball, but it was the latter that was his passion. From 11-years old, he played 
                                baseball from January to August every year. When he wasn’t playing baseball, he could be found in the press box with a microphone in his hand 
                                announcing the players. At that age he just tried to mimic other announcers he heard. One student at the high school he would eventually go to was 
                                named Doug Alexander. Doug had a presence on the microphone that made him amazing to listen to. Garett knew one say he wanted to sound like that. 
                                During high school, Garett also participated in musical theater, which gave him a chance to perform in front of a crowd which he enjoyed. 
                                <p/>
                                After high school, Garett continued to do some community theater until one day in 2001 he was in a AOL chatroom about pro wrestling and someone 
                                mentioned there was pro wrestling in Hillsboro, Oregon. Portland Wrestling folded in the summer of 1992, so Garett wasn’t sure what this person was 
                                talking about, but he travelled the 4 hours round trip to Hillsboro to watch this “wrestling.” To Garett’s surprise, it was nothing like what he saw 
                                on TV. The show was put on at a place called Tito’s Gym at a flea market and was ran by retired pro wrestler Tito Carreon.  The matches were in an 
                                old beat up ring, duct tape on the turnbuckles, a blue tarp separating the wrestler’s locker room from the crowd, and a ceiling so low you couldn’t 
                                stand on the top rope without hitting your head. It was beautiful. Garett went back eight straight weeks to watch these local pro wrestlers learn 
                                their craft and perform in front of the crowd. Garett went back home and called someone who he knew went to pro wrestling school, Jason Scott. 
                                Reluctantly, Jason agreed to teach Garett how to become a pro wrestler. Three months later, Garett had his first match at that flea market in 
                                Hillsboro where he first watched it. 
                                <p/>
                                Garett wrestled around the northwest from 2001 to 2010. In October of 2010, the aspiring wrestler had a match where he blacked out mid-match and 
                                doesn’t remember anything till after the match when he was in the shower.  That scared him enough to quit wrestling. 
                                <p/>
                                Still wanting to be in the wrestling business, Garett needed another way to be part of the show, so he asked the promoter of that promotion if 
                                he could be the ring announcer. As they say, the rest is history. 
                            </div>
                        </div>
                    </div>
                </center>
                <br />
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <Footer/>
            </React.Fragment>
        )
    }
}
export default About;

                