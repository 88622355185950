import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SideNav, { NavItem, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

class Header extends Component {

    buttonNavButtonClicked = () => {
        console.log(document.getElementById("divNav").style.display);
        document.getElementById("divNav").style.display = 'block';


    }

    render() {
        return (
            <React.Fragment>
                { !this.props.isMobile ? 
                    // desktop
                    <div className="flexContainer">
                        <Link className="ThemeFont MenuButton btn FontSizeLevel3" to="/">h o m e</Link> 
                        <Link className="ThemeFont MenuButton btn FontSizeLevel3" to="/about">a b o u t</Link> 
                        {/* <Link className="ThemeFont MenuButton btn" to="/sponsor">s p o n s o r</Link>  */}
                        <Link className="ThemeFont MenuButton btn FontSizeLevel3" to="/gallery">g a l l e r y</Link>
                        {/* <Link className="ThemeFont  FontSizeLevel4" style={{color:'white',textAlign:'right',marginRight:'50px'}}  to="/">Garett Fertig </Link> */}
                        <Link className="ThemeFont MenuButton btn FontSizeLevel3" to="/schedule">s c h e d u l e</Link> 
                        <Link className="ThemeFont MenuButton btn FontSizeLevel3" to="/promotions">p r o m o t i o n s</Link> 
                        <Link className="ThemeFont MenuButton btn FontSizeLevel3" to="/sponsors">s p o n s o r s</Link> 
                        <a href='mailto://bookings@garettfertig.com'>
                            <div className="ThemeFont MenuButton btn FontSizeLevel3">c o n t a c t</div>
                        </a>
                        <a href='AnnouncingResumeGarettFertig.pdf'>
                            <div className="ThemeFont MenuButton btn FontSizeLevel3">r e s u m e</div>
                        </a>
                        {/* <Link className="ThemeFont MenuButton btn" to="/contact">c o n t a c t</Link>  */}
                    </div>
                : 
                    //mobile - use side nav
                    <div>
                        <div>
                            <i className="fa fa-bars fa-2x" onClick={this.buttonNavButtonClicked.bind(this)} style={{padding:'20px'}}></i>
                        </div>

                        <div id="divNav" style={{display:'none'}}>
                            <SideNav expanded={true} style={{backgroundColor:'black'}}
                                onToggle={(selected) => { 
                                    var currentState = document.getElementById("divNav").style.display;
                                    if(currentState=="block") {
                                        document.getElementById("divNav").style.display = 'none'
                                    }
                                    else {
                                        document.getElementById("divNav").style.display = 'block'
                                    }
                                }}
                                onSelect={(selected) => {
                                    document.getElementById("divNav").style.display = 'none';
                                    }
                                }>
                                <SideNav.Toggle/>
                                    <SideNav.Nav defaultSelected="home">
                                    <NavItem eventKey="home">
                                        <NavText>
                                            <Link className="ThemeFont MenuButton btn  FontSizeLevel3" to="/">h o m e</Link> 
                                        </NavText>
                                    </NavItem>
                                    <NavItem eventKey="about">
                                        <NavText>
                                            <Link className="ThemeFont MenuButton btn  FontSizeLevel3" to="/about">a b o u t</Link> 
                                        </NavText>
                                    </NavItem>
                                    <NavItem eventKey="gallery">
                                        <NavText>
                                            <Link className="ThemeFont MenuButton btn  FontSizeLevel3" to="/gallery">g a l l e r y</Link> 
                                        </NavText>
                                    </NavItem>
                                    <NavItem eventKey="schedule">
                                        <NavText>
                                            <Link className="ThemeFont MenuButton btn FontSizeLevel3" to="/schedule">s c h e d u l e</Link> 
                                        </NavText>
                                    </NavItem>
                                    <NavItem eventKey="promotions">
                                        <NavText>
                                        <Link className="ThemeFont MenuButton btn FontSizeLevel3" to="/promotions">p r o m o t i o n s</Link> 
                                        </NavText>
                                    </NavItem>
                                    <NavItem eventKey="sponsors">
                                        <NavText>
                                        <Link className="ThemeFont MenuButton btn FontSizeLevel3" to="/sponsors">s p o n s o r s</Link> 
                                        </NavText>
                                    </NavItem>
                                    <NavItem eventKey="contact">
                                        <NavText>
                                        <a href='mailto://bookings@garettfertig.com'>
                                            <div className="ThemeFont MenuButton btn  FontSizeLevel3">c o n t a c t</div>
                                        </a> 
                                        </NavText>
                                    </NavItem>
                                    <NavItem eventKey="reume">
                                        <NavText>
                                        <a href='AnnouncingResumeGarettFertig.pdf'>
                                            <div className="ThemeFont MenuButton btn  FontSizeLevel3">r e s u m e</div>
                                        </a> 
                                        </NavText>
                                    </NavItem>
                                    <NavItem eventKey="social">
                                        <NavText style={{marginLeft:'30px'}}>
                                            <a href='https://www.youtube.com/user/garettfertig' target='_blank' rel="noopener noreferrer"><div className="fa fa-youtube-play" style={{color:'white',fontSize:'25px',padding:'10px'}}></div></a>
                                            <a href='https://twitter.com/garettfertig' target='_blank' rel="noopener noreferrer"><div className="fa fa-twitter" style={{color:'white',fontSize:'25px',padding:'10px'}}></div></a>
                                            <a href='https://www.instagram.com/garettfertig/' target='_blank' rel="noopener noreferrer"><div className="fa fa-instagram" style={{color:'white',fontSize:'25px',padding:'10px'}}></div></a>
                                            <a href='https://www.facebook.com/garett.fertig' target='_blank' rel="noopener noreferrer"><div className="fa fa-facebook" style={{color:'white',fontSize:'25px',padding:'10px'}}></div></a>
                                        </NavText>
                                    </NavItem>
                                </SideNav.Nav>
                            </SideNav>
                            <div>
                            
                        </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}
export default Header;