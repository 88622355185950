import React, { Component } from 'react';
import Moment from 'react-moment';

class ScheduleList extends Component {
    render() {
        return this.props.data.map((data) => (
            <tr>
                <td valign='top' className='DesktopOnly' style={{margin:10,width:'80px'}}>
                    <br/>
                    <div className='SubBodyText FontSizeLevel1' >
                    <Moment format="dddd">{data['0_date']}</Moment><br />
                    <Moment format="MMM DD, YYYY">{data['0_date']}</Moment>    
                    </div>
                </td>
                <td valign='top'>
                    <div style={{width:'10px'}} className="MobileOnly"></div>
                    <img src={data['3_promotion logo']} alt="" style={{margin:5,width:'80px'}}/>
                </td>
                <td>
                    <div style={{width:'15px'}}></div>
                </td>
                <td>
                    <div className='BodyText FontSizeLevel1' >{data['4_assignment']}</div>
                    <div className='ThemeFont  FontSizeLevel4' style={{marginTop:'-7px'}}>{data['2_promotion']}</div>
                    <div className='SubBodyText MobileOnly  FontSizeLevel1'>
                        <Moment format="dddd">{data['0_date']}</Moment>, <Moment format="MMM DD, YYYY">{data['0_date']}</Moment>    
                        </div>
                    <div className='SubBodyText FontSizeLevel1' style={{marginTop:'-2px'}} >Location: {data['1_location']}</div>
                    { data['5_ticket link'] ? <a href={data['5_ticket link']} className='SubBodyText FontSizeLevel1'><i className="fa fa-ticket"></i> tickets</a> : null }
                    <br/>
                    <br/>
                </td>
            </tr>
        ))
    }
}

export default ScheduleList;