import React, { Component } from 'react';

class SocialMedia extends Component {
    componentDidMount () {
        let isMobile = this.props.isMobile;
    }

    render() {
        return (
            <React.Fragment>
                { !this.props.isMobile ? 
                        <div>
                            <a href='https://www.youtube.com/user/garettfertig' target='_blank' rel="noopener noreferrer"><div className="fa fa-youtube-play" style={{color:'white',fontSize:'25px',padding:'10px'}}></div></a>
                            <a href='https://twitter.com/garettfertig' target='_blank' rel="noopener noreferrer"><div className="fa fa-twitter" style={{color:'white',fontSize:'25px',padding:'10px'}}></div></a>
                            <a href='https://www.instagram.com/garettfertig/' target='_blank' rel="noopener noreferrer"><div className="fa fa-instagram" style={{color:'white',fontSize:'25px',padding:'10px'}}></div></a>
                            <a href='https://www.facebook.com/garett.fertig' target='_blank' rel="noopener noreferrer"><div className="fa fa-facebook" style={{color:'white',fontSize:'25px',padding:'10px'}}></div></a>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                        </div>
                : null }
            </React.Fragment>

            
        )
    }
}
export default SocialMedia;