import React, { Component } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import Logo2PS from '../content/Logo2PS.png';
import LogoFFC from '../content/LogoFFC.png';
import LogoGG from '../content/LogoGG.png';
import LogoPC from '../content/LogoPC.png';
import LogoPNW from '../content/LogoPNW.png';
import LogoPOW from '../content/LogoPOW.png';
import LogoSKV from '../content/LogoSKV.png';
import LogoTSC from '../content/LogoTSC.png';
import LogoWDP from '../content/LogoWDP.png';
import LogoWU from '../content/LogoWU.png';

class Promotions extends Component {
    componentDidMount () {
        let isMobile = this.props.isMobile;
    }
    render() {
        return (
            <React.Fragment>
                <Header isMobile={this.props.isMobile}/>
                <center>
                    <div style={{width:'75%', textAlign:'center'}}>
                        <div className="ThemeFont FontSizeLevel7" style={{color:'white',textAlign:'left'}}>promotions</div>
                        <hr className="AccentColor" style={{marginTop:'-20px'}} />

                        <center className='DesktopOnly' style={{height:'1000px'}}>
                            <div className="FlexContainer">

                            <a href='https://finalfightchampionship.com/' target='_blank' rel="noopener noreferrer">
                                <img src={LogoFFC} alt="" style={{height:'120px',padding:'15px'}} />
                            </a>

                            <a href='https://www.facebook.com/purecombatmma/' target='_blank'  rel="noopener noreferrer">
                                <img src={LogoPC} alt="" style={{height:'120px',padding:'15px'}} />
                            </a>

                            <a href='http://trinitysportcombat.com/' target='_blank' rel="noopener noreferrer">
                                <img src={LogoTSC} alt="" style={{height:'120px',padding:'15px'}} />
                            </a>

                            <a href='https://www.facebook.com/powprowrestling/' target='_blank' rel="noopener noreferrer">
                                <img src={LogoPOW} alt="" style={{height:'120px',padding:'15px'}} />
                            </a>

                        </div>
                            <div className="FlexContainer" style={{width:'1000px'}}>

                                <a href='http://2poundsports.com' target='_blank' rel="noopener noreferrer">
                                    <img src={Logo2PS} alt="" style={{height:'150px',padding:'15px'}} />
                                </a>

                                <a href='https://www.facebook.com/Whitedelightpromotions/' target='_blank' rel="noopener noreferrer">
                                    <img src={LogoWDP} alt="" style={{height:'150px',padding:'15px'}} />
                                </a>

                                <a href='https://www.facebook.com/pnwproboxingpromotions/' target='_blank' rel="noopener noreferrer">
                                    <img src={LogoPNW} alt="" style={{height:'150px',padding:'15px'}} />
                                </a>

                                <a href='http://oregongoldengloves.com/' target='_blank' rel="noopener noreferrer">
                                    <img src={LogoGG} alt="" style={{height:'150px',padding:'15px'}} />
                                </a>
                            </div>
                            <div className="FlexContainer" style={{width:'1000px'}}>

                                <a href='https://willamette.edu/' target='_blank' rel="noopener noreferrer">
                                    <img src={LogoWU} alt="" style={{height:'150px',padding:'15px'}} />
                                </a>

                                <a href='https://www.milb.com/salem-keizer' target='_blank' rel="noopener noreferrer">
                                    <img src={LogoSKV} alt="" style={{height:'150px',padding:'15px'}} />
                                </a>
                            </div>
                            <br/>
                        </center>

                        <center className='MobileOnly'  style={{height:'1000px'}}>
                            <a href='https://finalfightchampionship.com/' target='_blank' rel="noopener noreferrer">
                                <img src={LogoFFC} alt="" style={{height:'80px',padding:'15px'}} />
                            </a>

                            <a href='https://www.facebook.com/purecombatmma/' target='_blank' rel="noopener noreferrer">
                                <img src={LogoPC} alt="" style={{height:'80px',padding:'15px'}} />
                            </a>

                            <a href='http://trinitysportcombat.com/' target='_blank' rel="noopener noreferrer">
                                <img src={LogoTSC} alt="" style={{height:'80px',padding:'15px'}} />
                            </a>

                            <a href='https://www.facebook.com/powprowrestling/' target='_blank' rel="noopener noreferrer">
                                <img src={LogoPOW} alt="" style={{height:'80px',padding:'15px'}} />
                            </a>

                            <a href='http://2poundsports.com' target='_blank' rel="noopener noreferrer">
                                    <img src={Logo2PS} alt="" style={{height:'80px',padding:'15px'}} />
                            </a>

                            <a href='https://www.facebook.com/Whitedelightpromotions/' target='_blank' rel="noopener noreferrer">
                                <img src={LogoWDP} alt="" style={{height:'80px',padding:'15px'}} />
                            </a>

                            <a href='https://www.facebook.com/pnwproboxingpromotions/' target='_blank' rel="noopener noreferrer">
                                <img src={LogoPNW} alt="" style={{height:'80px',padding:'15px'}} />
                            </a>

                            <a href='http://oregongoldengloves.com/' target='_blank' rel="noopener noreferrer">
                                <img src={LogoGG} alt="" style={{height:'80px',padding:'15px'}} />
                            </a>

                            <a href='https://willamette.edu/' target='_blank' rel="noopener noreferrer">
                                <img src={LogoWU} alt="" style={{height:'80px',padding:'15px'}} />
                            </a>

                            <a href='https://www.milb.com/salem-keizer' target='_blank' rel="noopener noreferrer">
                                <img src={LogoSKV} alt="" style={{height:'80px',padding:'15px'}} />
                            </a>
                        </center>
                    </div>
                </center>
                <Footer/>
            </React.Fragment>
        )
    }
}
export default Promotions;