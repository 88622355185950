import React, { Component } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
// import facePic from '../content/faceSquare.png';
// import interview from '../content/fertigInterview.jpg';

class Contact extends Component {

    render() {
        return (
            <React.Fragment>
                <Header/>
                <center>
                    <div style={{width:'75%', textAlign:'center'}}>
                        <div className="ThemeFont" style={{color:'white',textAlign:'left',fontSize:'80px'}}>contact</div>
                        <hr className="AccentColor" style={{marginTop:'-20px'}} />
                    </div>
                </center>
                <br />
            
                <Footer/>

            </React.Fragment>
            
        )
    }
}
export default Contact;