import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Gallery from './components/Gallery';
import Contact from './components/Contact';
import Schedule from './components/Schedule';
import Sponsors from './components/Sponsors';
import Promotions from './components/Promotions';
import axios from 'axios';
import './styles.css';

var CheckIfMobile = function(){
  if(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
    return true;
  }else{
    return false;
  }
}

class App extends Component {

  state = {
    isMobile: false,
    scheduleData: []
  }
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    let isMobile = CheckIfMobile();
    if(isMobile){
      this.setState({ isMobile : true });
    }
    else{
      this.setState({ isMobile : false });
    }

    //var url = 'https://hoarderwebapiv2.azurewebsites.net/api/data/public?inventorygroupid=739';
    var url = 'https://collectorwebapiwestus.azurewebsites.net/api/data/public?inventorygroupid=739'; //WUS
    axios.get(url).then(promise => {
        this.setState({ scheduleData: promise.data });
    });

  }

  render(){
    return (
      <Router>
          <Route exact path="/" render={props =>(
            <React.Fragment>
              <Home isMobile={this.state.isMobile} />
            </React.Fragment>
          )}/>
      
      <Route path="/sponsors" 
              render={props =>(
              <React.Fragment >
                  <Sponsors isMobile={this.state.isMobile}/>
              </React.Fragment>
              )}/>

        <Route path="/schedule" 
              render={props =>(
              <React.Fragment >
                  <Schedule isMobile={this.state.isMobile} data={this.state.scheduleData} />
              </React.Fragment>
              )}/>

        <Route path="/gallery" 
              render={props =>(
              <React.Fragment >
                  <Gallery isMobile={this.state.isMobile}/>
              </React.Fragment>
              )}/>

        <Route path="/promotions" 
              render={props =>(
              <React.Fragment >
                  <Promotions isMobile={this.state.isMobile}/>
              </React.Fragment>
              )}/>

        <Route path="/contact" 
              render={props =>(
              <React.Fragment >
                  <Contact isMobile={this.state.isMobile}/>
              </React.Fragment>
              )}/>

        <Route path="/about" 
              render={props =>(
              <React.Fragment >
                <About isMobile={this.state.isMobile}/>
              </React.Fragment>
              )}/>


      </Router>

    );
  }
}

export default App;
