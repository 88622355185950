import React, { Component } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import ScheduleList from './ScheduleList';

class Schedule extends Component {
    componentDidMount () {
        let isMobile = this.props.isMobile;
    }
    render() {
        return (
            <React.Fragment>
                <Header isMobile={this.props.isMobile}/>
                <center>
                    <div style={{width:'75%', textAlign:'center'}}>
                        <div className="ThemeFont FontSizeLevel7" style={{color:'white',textAlign:'left'}}>schedule</div>
                        <hr className="AccentColor" style={{marginTop:'-20px'}} />
                    </div>
                </center>

                <center>
                    <table className='BodyText' style={{marginLeft:'50px'}}>
                        <tbody>
                            <ScheduleList data={this.props.data}/>
                        </tbody>
                    </table>
                </center>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <Footer/>

            </React.Fragment>
            
        )
    }
}
export default Schedule;