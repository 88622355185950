import React, { Component } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import SocialMedia from '../common/SocialMedia';
import interview from '../content/fertigInterview.jpg';
import LogoFiverr from '../content/LogoFiverr.png';

class Home extends Component {
    componentDidMount () {
        let isMobile = this.props.isMobile;
    }
    
    render() {
        return (
            <React.Fragment>
                <Header isMobile={this.props.isMobile}/>
                <center>
                <table>
                    <tbody>
                        <tr>
                            <td></td>
                            <td style={{textAlign:'right'}}>
                                <SocialMedia isMobile={this.props.isMobile}/>
                            </td>   
                        </tr>
                        <tr>
                        <td>
                            <div>
                                <img className="ImageHero" src={interview} alt="" />
                                <center className='MobileOnly'>
                                    <div className='ThemeFont FontSizeLevel3' style={{color:'white',width:'320px',padding:'20px',textAlign:'center',backgroundColor:'rgba(0,0,0,0.75)', marginTop:'-20px'}}>
                                        announcer/mc, brazilian jiu jitsu practitioner, combat sports ethusiast, always looking to learn and grow in life</div>                            
                                </center>
                            <br />
                            </div>
                        </td>
                        <td valign='top'>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <div className='ThemeFont DesktopOnly FontSizeLevel3' style={{color:'white',width:'300px',padding:'30px',textAlign:'left',backgroundColor:'rgba(0,0,0,0.75)'}}>
                                    announcer/mc, brazilian jiu jitsu practitioner, combat sports ethusiast, always looking to learn and grow in life
                                </div>                            
                            <br />
                            {/* <div className='SignatureFont TaglineDesktop' style={{color:'dimgray',textAlign:'right',fontSize:'50px', transform: 'rotate(-10deg)'}}>
                                Garett Fertig
                            </div> */}
                        </td>
                    </tr>
                    </tbody>
                </table>
                <br/>
                <br/>
                <br/>
                <br/>
                <center>
                    <div className="ThemeFont FontSizeLevel5" style={{color:'white',textAlign:'center'}}>are you ready for some Action Packed Action!</div>
                </center>
                <br/>
                <div className="MobileOnly">
                    <iframe title="montage" width="300" height="240" src="https://www.youtube.com/embed/2Gs3MkiDfPw" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    <br/>
                    <br/>
                    <iframe title="playlist" width="320" height="240" src="https://www.youtube.com/embed/QikTRgr_3Hg" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>                        
                </div>

                <div className="DesktopOnly">
                    <iframe title="montage"  width="640" height="480" src="https://www.youtube.com/embed/2Gs3MkiDfPw" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    <br/>
                    <br/>
                    <iframe title="playlist"  width="640" height="480" src="https://www.youtube.com/embed/QikTRgr_3Hg" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>                        
                </div>

                <br/>
                <br/>
                
                <center>
                    <div className="ThemeFont FontSizeLevel5" style={{color:'white',textAlign:'center'}}>I will deliver an energetic professional voiceover for you!</div>
                </center>
                
                <a href='https://www.fiverr.com/garettfertig/deliver-an-energetic-professional-voiceover-for-you'>
                    <div className="BodyText">Book now on 
                        <span> 
                            <img src={LogoFiverr} alt="" style={{height:'100px',padding:'10px'}}/>
                        </span>
                    </div>
                </a>
                
                 

                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                </center>
                <Footer/>
            </React.Fragment>
        )
    }
}
export default Home;