import React, { Component } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import ImageGallery from 'react-image-gallery';
// import facePic from '../content/faceSquare.png';
// import interview from '../content/fertigInterview.jpg';
import "react-image-gallery/styles/css/image-gallery.css";

import fertig001 from '../content/fertig001.jpg';
import fertig002 from '../content/fertig002.jpg';
import fertig003 from '../content/fertig003.jpg';
import fertig004 from '../content/fertig004.jpg';
import fertig005 from '../content/fertig005.jpg';
import fertig006 from '../content/fertig006.jpg';
import fertig007 from '../content/fertig007.jpg';
import fertig008 from '../content/fertig008.jpg';
import fertig009 from '../content/fertig009.jpg';
import fertig010 from '../content/fertig010.jpg';
import fertig011 from '../content/fertig011.jpg';
import fertig012 from '../content/fertig012.jpg';
import fertig013 from '../content/fertig013.jpg';
import fertig014 from '../content/fertig014.jpg';
import fertig015 from '../content/fertig015.jpg';
import fertig016 from '../content/fertig016.jpg';
import fertig017 from '../content/fertig017.jpg';
import fertig018 from '../content/fertig018.jpg';
import fertig019 from '../content/fertig019.jpg';
import fertig020 from '../content/fertig020.jpg';
import fertig021 from '../content/fertig021.jpg';
import fertig022 from '../content/fertig022.jpg';
import fertig023 from '../content/fertig023.jpg';
import fertig024 from '../content/fertig024.jpg';
import fertig025 from '../content/fertig025.jpg';
import fertig026 from '../content/fertig026.jpg';
import fertig027 from '../content/fertig027.jpg';
import fertig028 from '../content/fertig028.jpg';
import fertig029 from '../content/fertig029.jpg';

import fertig001thumb from '../content/fertig001thumb.jpg';
import fertig002thumb from '../content/fertig002thumb.jpg';
import fertig003thumb from '../content/fertig003thumb.jpg';
import fertig004thumb from '../content/fertig004thumb.jpg';
import fertig005thumb from '../content/fertig005thumb.jpg';
import fertig006thumb from '../content/fertig006thumb.jpg';
import fertig007thumb from '../content/fertig007thumb.jpg';
import fertig008thumb from '../content/fertig008thumb.jpg';
import fertig009thumb from '../content/fertig009thumb.jpg';
import fertig010thumb from '../content/fertig010thumb.jpg';
import fertig011thumb from '../content/fertig011thumb.jpg';
import fertig012thumb from '../content/fertig012thumb.jpg';
import fertig013thumb from '../content/fertig013thumb.jpg';
import fertig014thumb from '../content/fertig014thumb.jpg';
import fertig015thumb from '../content/fertig015thumb.jpg';
import fertig016thumb from '../content/fertig016thumb.jpg';
import fertig017thumb from '../content/fertig017thumb.jpg';
import fertig018thumb from '../content/fertig018thumb.jpg';
import fertig019thumb from '../content/fertig019thumb.jpg';
import fertig020thumb from '../content/fertig020thumb.jpg';
import fertig021thumb from '../content/fertig021thumb.jpg';
import fertig022thumb from '../content/fertig022thumb.jpg';
import fertig023thumb from '../content/fertig023thumb.jpg';
import fertig024thumb from '../content/fertig024thumb.jpg';
import fertig025thumb from '../content/fertig025thumb.jpg';
import fertig026thumb from '../content/fertig026thumb.jpg';
import fertig027thumb from '../content/fertig027thumb.jpg';
import fertig028thumb from '../content/fertig028thumb.jpg';
import fertig029thumb from '../content/fertig029thumb.jpg';


class Gallery extends Component {
    componentDidMount () {
        let isMobile = this.props.isMobile;
    }

    render() {
        const images = [
            { original: fertig001, thumbnail: fertig001thumb },
            { original: fertig002, thumbnail: fertig002thumb },
            { original: fertig003, thumbnail: fertig003thumb },
            { original: fertig004, thumbnail: fertig004thumb },
            { original: fertig005, thumbnail: fertig005thumb },
            { original: fertig006, thumbnail: fertig006thumb },
            { original: fertig007, thumbnail: fertig007thumb },
            { original: fertig008, thumbnail: fertig008thumb },
            { original: fertig009, thumbnail: fertig009thumb },
            { original: fertig010, thumbnail: fertig010thumb },
            { original: fertig011, thumbnail: fertig011thumb },
            { original: fertig012, thumbnail: fertig012thumb },
            { original: fertig013, thumbnail: fertig013thumb },
            { original: fertig014, thumbnail: fertig014thumb },
            { original: fertig015, thumbnail: fertig015thumb },
            { original: fertig016, thumbnail: fertig016thumb },
            { original: fertig017, thumbnail: fertig017thumb },
            { original: fertig018, thumbnail: fertig018thumb },
            { original: fertig019, thumbnail: fertig019thumb },
            { original: fertig020, thumbnail: fertig020thumb },
            { original: fertig021, thumbnail: fertig021thumb },
            { original: fertig022, thumbnail: fertig022thumb },
            { original: fertig023, thumbnail: fertig023thumb },
            { original: fertig024, thumbnail: fertig024thumb },
            { original: fertig025, thumbnail: fertig025thumb },
            { original: fertig026, thumbnail: fertig026thumb },
            { original: fertig027, thumbnail: fertig027thumb },
            { original: fertig028, thumbnail: fertig028thumb },
            { original: fertig029, thumbnail: fertig029thumb }
          ]
      

        return (
            <React.Fragment>
                <Header isMobile={this.props.isMobile}/>
                <center>
                    <div style={{width:'75%', textAlign:'center'}}>
                        <div className="ThemeFont FontSizeLevel7" style={{color:'white',textAlign:'left'}}>gallery</div>
                        <hr className="AccentColor" style={{marginTop:'-20px'}} />
                    </div>

                    <div style={{padding:'0px 50px 50px 50px',maxWidth:'800px',textAlign:'center'}}>
                        <ImageGallery items={images} />
                    </div>
                </center>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <Footer/>

            </React.Fragment>
            
        )
    }
}
export default Gallery;