import React, { Component } from 'react';
import CombatComboverBanner from '../content/ScoringSenateBanner.png';
import ScoringSenateBanner from '../content/CombatComboverBanner.png';
import PureBodyZenBanner from '../content/PureBodyZenBanner.png';
import ProSocksBanner from '../content/ProSocksBanner.png';
import EliteSportsBanner from '../content/EliteSportsBanner.png';
import BornToughBanner from '../content/BornToughBanner.png';
import CSCDPBJJBanner from '../content/CSCDPBJJBanner.png';

class Footer extends Component {

    render() {
        return (
            <div className='footer' style={{textAlign:'left'}}>
                <div className={'SubBodyText FontSizeLevel0'} style={{color:'white',marginLeft:'30px'}}> S P O N S O R S </div>
                <div className={'flexContainerLeft'} >
                    <a href='https://CombatCombover.com/' target='_blank'><img src={ScoringSenateBanner} alt="" style={{height:'40px',marginRight:'15px',marginLeft:'15px'}} /></a>
                    {/*<a href='https://ScoringSenate.com/'  target='_blank'><img src={CombatComboverBanner} alt="" style={{height:'40px',marginLeft:'15px'}}/></a> */}
                    <a href='https://ProSocks.com/'  target='_blank'><img src={ProSocksBanner} alt="" style={{height:'40px',marginLeft:'15px'}}/></a>
                    <a href='https://EliteSports.com/'  target='_blank'><img src={EliteSportsBanner} alt="" style={{height:'40px',marginLeft:'15px'}}/></a>
                    <a href='https://BornTough.com/'  target='_blank'><img src={BornToughBanner} alt="" style={{height:'40px',marginLeft:'15px'}}/></a>
                    <a href='https://combatsportscenters.com/'  target='_blank'><img src={CSCDPBJJBanner} alt="" style={{height:'40px',marginLeft:'15px'}}/></a>
                </div>
                <br />
            </div>
        )
    }
}
export default Footer;